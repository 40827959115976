<template>
  <div>
    <div class="mb-4">
      <template>
        <div>
          <v-breadcrumbs
            :items="breadcrumb"
            class="px-0 pt-0"
          >
          </v-breadcrumbs>
        </div>
      </template>
      <router-link
        to="/bank-admin"
        class="text-decoration-none text-subtitle-2 font-weight-bold"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </router-link>
    </div>
    <h3 class="my-8 text-capitalize">
      {{ bankQuestion.name }}
    </h3>
    <v-card>
      <v-card-text class="pa-8">
        <div class="mb-4">
          <div class="mb-8">
            <v-row>
              <v-col md="2">
                <div>
                  <h3 class="mb-3">
                    Judul Bank Soal
                  </h3>
                  <span class="text-capitalize">{{ bankQuestion.name }}</span>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <h3 class="mb-3">
                    Kategori
                  </h3>
                  <span>{{ bankQuestion.category.name }}</span>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <h3 class="mb-3">
                    Tingkat
                  </h3>
                  <span>{{ bankQuestion.level }}</span>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <h3 class="mb-3">
                    Jumlah Soal
                  </h3>
                  <span>{{ bankQuestion.question.length }}</span>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <h3 class="mb-3">
                    Tanggal Dibuat
                  </h3>
                  <span>{{ getFormatDate(bankQuestion.created_at) }}</span>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <h3 class="mb-3">
                    Status
                  </h3>
                  <v-chip
                    color="success"
                    small
                    text
                    class="bank-chip text-capitalize"
                  >
                    {{ bankQuestion.type }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mb-4">
          <div class="my-8">
            <h3>Soal</h3>
            <span>Soal untuk bank soal</span>
          </div>
          <div
            v-if="bankQuestion.question.length < 1"
            class="mb-4"
          >
            <p class="text-center">
              Belum ada soal
            </p>
          </div>
          <div class="mb-4">
            <v-card
              v-for="(questionItem,index) in bankQuestion.question"
              v-show="bankQuestion.question.length"
              :key="index"
              class="mb-2"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="align-center">
                      <v-col md="3">
                        <v-chip
                          outlined
                          class="v-chip-light-bg success--text"
                          h-e-a-d
                          @click="checkDescription(questionItem.question)"
                        >
                          Lihat Deskripsi
                        </v-chip>
                      </v-col>
                      <v-col md="3">
                        <span>{{ questionItem.answer }}</span>
                      </v-col>
                      <v-col md="4">
                        <span>{{ questionItem.discussion }}</span>
                      </v-col>
                      <v-col md="1">
                        <a
                          class="text-decoration-none"
                          @click.prevent="editQuestion(questionItem.uuid)"
                        >Edit</a>
                      </v-col>
                      <v-col md="1">
                        <a
                          class="text-decoration-none"
                          @click.prevent="confirmDestroy(questionItem.uuid)"
                        >Hapus</a>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>Pembahasan</p>
                    {{ questionItem.discussion }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
          <div
            v-for="(question, index) of questions"
            :key="question.question"
          >
            <div class="d-flex justify-space-between align-center mb-5">
              <div>
                <h3>
                  Soal
                </h3>
              </div>
              <div>
                <v-btn
                  color="primary pa-2"
                  icon
                  small
                  @click="deleteQuestion(index)"
                >
                  <v-icon>
                    {{ icons.mdiCloseCircleOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="mb-4">
              <CKEditor
                :value="question[index].question"
                label="Content"
                outlined
                dense
                @input="handleCKeditor($event, index)"
              ></CKEditor>
            </div>
            <div>
              <v-text-field
                v-model="question[index].answer"
                label="Jawaban"
                outlined
                type="text"
                dense
              >
              </v-text-field>
            </div>
            <div class="mb-4">
              <h4 class="mb-4">
                Masukkan Gambar (jika ada)
              </h4>
              <FileInput
                :value="question[index].image"
                label="Gambar"
                outlined
                dense
                :disabled="false"
                :prependicon="icons.mdiImageMultipleOutline"
                @input="getFile"
              ></FileInput>
            </div>
            <div>
              <v-textarea
                v-model="question[index].discussion"
                label="Pembahasan"
                outlined
                dense
              >
              </v-textarea>
            </div>
          </div>
        </div>

        <div>
          <div v-show="isHide">
            <v-card-actions class="d-flex justify-center mb-6">
              <v-btn
                color="primary"
                large
                :loading="isLoadingButton"
                :disabled="isDisabledButton"
                @click="addQuestion()"
              >
                Simpan
              </v-btn>
              <v-btn
                color="primary"
                outlined
                large
                @click="deleteQuestion()"
              >
                Batal
              </v-btn>
            </v-card-actions>
          </div>
          <v-divider></v-divider>
          <div class="my-10">
            <a
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click.prevent="showFormQuestion"
            ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> TAMBAH SOAL</a>
             <a
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="showFormArchive"
            ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> AMBIL DARI ARSIP SOAL</a>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi Soal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="editDialogQuestion"
      :width="600"
      :is-loading-button="isLoadingButton"
      header="Edit Soal"
      text-button="Update"
      @close-modal="editDialogQuestion = false"
      @close-button="editDialogQuestion = false"
      @handler-button="updateQuestion"
    >
      <template v-slot:body>
        <div class="mb-4">
          <CKEditor
            :value="initialContent"
            label="Soal"
            outlined
            dense
            @input="handleCKeditor($event, 'edit')"
          ></CKEditor>
        </div>
        <div>
          <v-text-field
            v-model="questionEdit.answer"
            label="Jawaban"
            outlined
            dense
          ></v-text-field>
        </div>
        <div class="mb-4">
          <h4 class="mb-4">
            Masukkan Gambar (jika ada)
          </h4>
          <FileInput
            v-model="initialFile"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <div>
          <v-textarea
            v-model="questionEdit.discussion"
            label="Pembahasan"
            outlined
            dense
          >
          </v-textarea>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Soal"
      @close-button="confirmDialog = false"
      @destroy-button="destroyQuestion"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CKEditor from '@/views/components/CkEditor.vue'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import { mdiArrowLeft, mdiCloseCircleOutline, mdiImageMultipleOutline, mdiNoteOutline, mdiPlus } from '@mdi/js'

export default {
  name: 'BankQuestionEdit',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
    CKEditor,
  },
  data() {
    return {
      modalDescription: false,
      widthModal: 810,
      icons: {
        mdiNoteOutline,
        mdiPlus,
        mdiImageMultipleOutline,
        mdiCloseCircleOutline,
        mdiArrowLeft,
      },
      breadcrumb: [
        {
          text: 'Bank Soal',
          disabled: false,
          href: '/bank-admin',
        },
        {
          text: 'Detail Bank Soal',
          disabled: true,
        },
      ],
      isHide: false,
      isLoadingButton: false,
      isDisabledButton: false,
      confirmDialog: false,
      editDialogQuestion: false,
      questionItems: [],
      questionItem: {},
      questionEdit: {
        question: null,
      },
      question: [],
      questions: [],
      questionContent: [],
      questionContentImage: [],
      selected: [],
      numberQuestion: 0,
      bankQuestion: {
        category: {},
        question: [],
      },
      tempContent: '',
      tempImage: [],
      detail: '',
    }
  },
  computed: {
    initialContent: {
      get() {
        if (this.questionEdit.question === null) {
          return ''
        }

        return this.questionEdit.question
      },
      set() {
        return this.questionEdit.question
      },
    },
    initialFile: {
      get() {
        if (typeof this.questionEdit.image === 'string') {
          return new File([this.questionEdit.image], this.questionEdit.image, { type: 'text/plain' })
        }

        return this.questionEdit.image
      },
      set() {
        return this.questionEdit.image
      },
    },
  },
  mounted() {
    this.getBankQuestion(this.$route.params.uuid)
  },
  methods: {
    checkDescription(data) {
      this.detail = data

      this.modalDescription = true
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event, index) {
      if (index !== 'edit') {
        this.questionContent[index].question = event
      } else {
        this.questionEdit.question = event
      }

      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    async getBankQuestion(uuid) {
      await this.$services.ApiServices.get('questionbank', uuid).then(({ data }) => {
        this.bankQuestion = data.data
      })
    },
    getFile(data) {
      this.questionContentImage = data
    },
    showFormQuestion() {
      this.tempContent = ''
      this.tempImage = []
      this.questionContent = [
        ...this.questionContent,
        {
          question_bank_uuid: this.$route.params.uuid,
          question: '',
          answer: '',
          discussion: '',
          image: null,
        },
      ]
      // this.question.number = this.question.length + 1
      this.questions.push(this.questionContent)
      this.isHide = true
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      const time = createdDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

      return `${year}-${month.slice(-2)}-${day.slice(-2)} ${time}`
    },
    async addQuestion() {
      this.isLoadingButton = true
      this.questionContent.forEach(async (item, index) => {
        this.formData = new FormData()
        this.formData.append('question_bank_uuid', this.$route.params.uuid)
        this.formData.append('question', item.question)
        this.formData.append('image', this.questionContentImage)
        this.formData.append('discussion', item.discussion)
        this.formData.append('answer', item.answer)
        if (typeof this.questionContentImage === 'undefined') {
          this.formData.delete('image')
        }
        const tempImage = this.tempContent.split('"')
        tempImage.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
        const image = item.question.split('"')
        image.forEach(item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            const carIndex = this.tempImage.indexOf(img)
            this.tempImage.splice(carIndex, 1)

            this.tempImage.forEach(async item => {
              await this.deleteStorage({ file: item })
            })
          }
        })
        await this.$services.ApiServices.add('questioncontent', this.formData).then(
          ({ data }) => {
            this.questionContent = data.data
            this.showSnackbar({
              text: data.result.message,
              color: 'success',
            })
            this.getBankQuestion(this.$route.params.uuid)
            this.isLoadingButton = false
          },
          err => {
            this.isLoadingButton = false
            console.error(err)
          },
        )
        this.isHide = false
        this.questions.splice(index, this.questions.length)
        if (this.questions.length < 1) {
          this.isHide = false
        }
        this.questionContent = []
        this.questionContentImage = []
      })
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.questionItem.uuid = uuid
    },
    async destroyQuestion() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('questioncontent', this.questionItem.uuid).then(({ data }) => {
        this.questionItem = data.data
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      this.getBankQuestion(this.$route.params.uuid)
      this.isLoadingButton = false
      this.confirmDialog = false
    },
    async editQuestion(uuid) {
      this.tempContent = ''
      this.tempImage = []
      this.questionItem.uuid = uuid
      this.editDialogQuestion = true
      await this.$services.ApiServices.get('questioncontent', uuid).then(({ data }) => {
        this.questionEdit = data.data
        const image = data.data.question.split('"')
        image.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
      })
    },
    async updateQuestion() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('question', this.questionEdit.question)
      this.formData.append('answer', this.questionEdit.answer)
      this.formData.append('image', this.questionContentImage)
      this.formData.append('discussion', this.questionEdit.discussion)
      if (this.questionEdit.image === 'string') {
        this.formData.delete('image')
      }
      const image = this.questionEdit.question.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('questioncontent', this.formData, this.questionItem.uuid).then(
        ({ data }) => {
          this.questionItem = data.data
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.editDialogQuestion = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.getBankQuestion(this.$route.params.uuid)
    },
    deleteQuestion(index) {
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''
      this.questions.splice(index, 1)
      if (this.questions.length < 1) {
        this.isHide = false
      }
    },

    returnBack() {
      this.$router.back()
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.v-chip.bank-chip::v-chip {
  padding: 3px 20px !important;
}
</style>
